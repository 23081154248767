import { ChartLegendOptions, ChartTooltipOptions, ChartOptions } from 'chart.js';

export const zeroWhy = (elements: any[]) => {
  let x = 0;
  elements.forEach((item) => {
    if (item && item.hasValue()) {
      x += item.tooltipPosition().x;
    }
  });
  return {
    x: x / elements.length,
    y: 0,
  };
};

const tooltips: ChartTooltipOptions = {
  backgroundColor: '#060606',
  bodyFontColor: '#bbb',
  bodyFontSize: 13,
  bodySpacing: 5,
  borderColor: '#444',
  borderWidth: 1,
  caretSize: 0,
  callbacks: {
    label: function (tooltipItem, data) {
      if (data.datasets === undefined || tooltipItem.datasetIndex === undefined) {
        return '';
      } else {
        const label = data.datasets[tooltipItem.datasetIndex].label;
        const value = Number(tooltipItem.yLabel);
        return `${label}: ${value}`;
      }
    },
  },
  filter: (item: any) => item.value > 0,
  intersect: false,
  mode: 'index',
  multiKeyBackground: '#000',
  position: 'zeroWhy',
  titleFontSize: 13,
  titleSpacing: 5,
};

const tooltipsPercent: ChartTooltipOptions = {
  ...tooltips,
  callbacks: {
    label: function (tooltipItem, data) {
      if (data.datasets === undefined || tooltipItem.datasetIndex === undefined || tooltipItem.index === undefined) {
        return '';
      } else {
        const label = data.datasets[tooltipItem.datasetIndex].label;
        const value = (data.datasets[tooltipItem.datasetIndex] as any).rawData[tooltipItem.index];
        const percent = Math.round(Number(tooltipItem.yLabel) * 10) / 10;
        return `${label}: ${value} (${percent}%)`;
      }
    },
  },
};

const legend: ChartLegendOptions = {
  display: true,
  labels: {
    fontColor: '#666',
    fontSize: 13,
  },
  position: 'bottom',
};

const lineScales = {
  xAxes: [
    {
      gridLines: {
        color: 'rgba(128,128,128,0.2',
      },
      stacked: false,
      ticks: {
        min: 0,
      },
    },
  ],
  yAxes: [
    {
      gridLines: {
        color: 'rgba(128,128,128,0.2',
      },
      id: 'bar-y-axis',
      stacked: false,
      ticks: {
        min: 0,
      },
    },
  ],
};

const scales = {
  xAxes: [
    {
      gridLines: {
        color: 'rgba(128,128,128,0.2',
      },
      stacked: false,
      ticks: {
        min: 0,
      },
    },
  ],
  yAxes: [
    {
      gridLines: {
        color: 'rgba(128,128,128,0.2',
      },
      id: 'bar-y-axis',
      stacked: false,
      ticks: {
        min: 0,
      },
    },
    {
      gridLines: {
        display: false,
      },
      id: 'line-y-axis',
      position: 'right',
      stacked: false,
      ticks: {
        min: 0,
      },
    },
  ],
};

const scalesStacked = {
  xAxes: [
    {
      gridLines: {
        color: 'rgba(128,128,128,0.2',
      },
      stacked: true,
      ticks: {
        min: 0,
      },
    },
  ],
  yAxes: [
    {
      gridLines: {
        color: 'rgba(128,128,128,0.2',
      },
      id: 'bar-y-axis',
      stacked: true,
      ticks: {
        min: 0,
      },
    },
    {
      gridLines: {
        display: false,
      },
      id: 'line-y-axis',
      position: 'right',
      stacked: false,
      ticks: {
        min: 0,
      },
    },
  ],
};

export const uiLineOptions = {
  legend,
  maintainAspectRatio: false,
  scales: lineScales,
};

export const uiBarStackOptions: ChartOptions = {
  legend,
  maintainAspectRatio: false,
  scales: scalesStacked,
  tooltips,
};

export const uiPercentStackOptions: ChartOptions = {
  legend,
  maintainAspectRatio: false,
  scales: scalesStacked,
  tooltips: tooltipsPercent,
};

export const uiBarGroupOptions: ChartOptions = {
  legend,
  maintainAspectRatio: false,
  scales,
  tooltips,
};
