export const colors = [
  { primary: 'rgba(239, 83, 80, 0.7)', secondary: 'rgba(239, 83, 80, 0.7)' },
  { primary: 'rgba(236, 64, 122, 0.7)', secondary: 'rgba(236, 64, 122, 0.7)' },
  { primary: 'rgba(171, 71, 188, 0.7)', secondary: 'rgba(171, 71, 188, 0.7)' },
  { primary: 'rgba(126, 87, 194, 0.7)', secondary: 'rgba(126, 87, 194, 0.7)' },
  { primary: 'rgba(92, 107, 192, 0.7)', secondary: 'rgba(92, 107, 192, 0.7)' },
  { primary: 'rgba(66, 165, 245, 0.7)', secondary: 'rgba(66, 165, 245, 0.7)' },
  { primary: 'rgba(38, 198, 218, 0.7)', secondary: 'rgba(38, 198, 218, 0.7)' },
  { primary: 'rgba(38, 166, 154, 0.7)', secondary: 'rgba(38, 166, 154, 0.7)' },
  { primary: 'rgba(102, 187, 106, 0.7)', secondary: 'rgba(102, 187, 106, 0.7)' },
  { primary: 'rgba(156, 204, 101, 0.7)', secondary: 'rgba(156, 204, 101, 0.7)' },
  { primary: 'rgba(212, 225, 87, 0.7)', secondary: 'rgba(212, 225, 87, 0.7)' },
  { primary: 'rgba(255, 238, 88, 0.7)', secondary: 'rgba(255, 238, 88, 0.7)' },
  { primary: 'rgba(255, 202, 40, 0.7)', secondary: 'rgba(255, 202, 40, 0.7)' },
  { primary: 'rgba(255, 167, 38, 0.7)', secondary: 'rgba(255, 167, 38, 0.7)' },
  { primary: 'rgba(255, 112, 67, 0.7)', secondary: 'rgba(255, 112, 67, 0.7)' },
  { primary: 'rgba(175, 68, 34, 0.7)', secondary: 'rgba(175, 68, 34, 0.7)' },
  { primary: 'rgba(141, 110, 99, 0.7)', secondary: 'rgba(141, 110, 99, 0.7)' },
  { primary: 'rgba(189, 189, 189, 0.7)', secondary: 'rgba(189, 189, 189, 0.7)' },
  { primary: 'rgba(120, 144, 156, 0.7)', secondary: 'rgba(120, 144, 156, 0.7)' },
  { primary: 'rgba(60, 60, 60, 0.4)', secondary: 'rgba(60, 60, 60, 0.4)' },
  { primary: 'rgba(239, 83, 80, 0.4)', secondary: 'rgba(239, 83, 80, 0.4)' },
  { primary: 'rgba(236, 64, 122, 0.4)', secondary: 'rgba(236, 64, 122, 0.4)' },
  { primary: 'rgba(171, 71, 188, 0.4)', secondary: 'rgba(171, 71, 188, 0.4)' },
  { primary: 'rgba(126, 87, 194, 0.4)', secondary: 'rgba(126, 87, 194, 0.4)' },
  { primary: 'rgba(92, 107, 192, 0.4)', secondary: 'rgba(92, 107, 192, 0.4)' },
  { primary: 'rgba(66, 165, 245, 0.4)', secondary: 'rgba(66, 165, 245, 0.4)' },
  { primary: 'rgba(38, 198, 218, 0.4)', secondary: 'rgba(38, 198, 218, 0.4)' },
  { primary: 'rgba(38, 166, 154, 0.4)', secondary: 'rgba(38, 166, 154, 0.4)' },
  { primary: 'rgba(102, 187, 106, 0.4)', secondary: 'rgba(102, 187, 106, 0.4)' },
  { primary: 'rgba(156, 204, 101, 0.4)', secondary: 'rgba(156, 204, 101, 0.4)' },
  { primary: 'rgba(212, 225, 87, 0.4)', secondary: 'rgba(212, 225, 87, 0.4)' },
  { primary: 'rgba(255, 238, 88, 0.4)', secondary: 'rgba(255, 238, 88, 0.4)' },
  { primary: 'rgba(255, 202, 40, 0.4)', secondary: 'rgba(255, 202, 40, 0.4)' },
  { primary: 'rgba(255, 167, 38, 0.4)', secondary: 'rgba(255, 167, 38, 0.4)' },
  { primary: 'rgba(255, 112, 67, 0.4)', secondary: 'rgba(255, 112, 67, 0.4)' },
  { primary: 'rgba(175, 68, 34, 0.4)', secondary: 'rgba(175, 68, 34, 0.4)' },
  { primary: 'rgba(141, 110, 99, 0.4)', secondary: 'rgba(141, 110, 99, 0.4)' },
  { primary: 'rgba(189, 189, 189, 0.4)', secondary: 'rgba(189, 189, 189, 0.4)' },
  { primary: 'rgba(120, 144, 156, 0.4)', secondary: 'rgba(120, 144, 156, 0.4)' },
  { primary: 'rgba(60, 60, 60, 0.2)', secondary: 'rgba(60, 60, 60, 0.2)' },
  { primary: 'rgba(0, 0,0, 0.4)', secondary: 'rgba(0,0,0, 0.4)' },
];

export const primaryColor = (index: number, optionColors: string) => {
  const colorIndex = index % colors.length;
  if (optionColors.split(',').length > colorIndex) {
    return colors[Number(optionColors.split(',')[colorIndex])].primary;
  } else {
    return colors[colorIndex].primary;
  }
};

export const secondaryColor = (index: number, optionColors: string) => {
  const colorIndex = index % colors.length;
  if (optionColors.split(',').length > colorIndex) {
    return colors[Number(optionColors.split(',')[colorIndex])].secondary;
  } else {
    return colors[colorIndex].secondary;
  }
};

export const getColor = (index: number, palette: string[]) => {
  return palette.length > index ? palette[index] : colors[index].primary;
};
