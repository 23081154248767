import { SimpleOptions } from '../types';
import {} from '@grafana/ui';
import { DataFrame, TimeRange, PanelData, Field, Vector } from '@grafana/data';
import {
  format,
  subDays,
  isSameDay,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  endOfYear,
  startOfYear,
  isMonday,
  isSunday,
} from 'date-fns';
import { getColor } from './customColors';
import { ChartData } from 'chart.js';

const timeIndex = 0; // headings in fields(0)

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type dataSetAble = {
  yAxisID: string;
  data: number[];
};

export const findMaxes = (uiData: ChartData, type: string) => {
  const intervals = [...(uiData.labels as string[])];
  const maxAmounts = [
    ...getValuesByAxis(intervals, uiData.datasets as dataSetAble[], 'bar-y-axis'),
    ...getValuesByAxis(intervals, uiData.datasets as dataSetAble[], 'line-y-axis'),
  ];
  const max =
    type === 'StackedBar'
      ? Math.max(...maxAmounts.map((ma) => ma.total))
      : Math.max(...maxAmounts.map((ma) => ma.highest));
  const divider = Math.pow(10, Math.floor(Math.log10(max))) / 2;
  // const divider = Math.pow(10, Math.floor(max).toString().length - 1) / 2;
  return Math.ceil(max / divider) * divider;
};

const getValuesByAxis = (intervals: string[], datasets: dataSetAble[], axis: string) => {
  return intervals.map((label, period) => {
    const values = getValuesInPeriod(datasets, axis, period);
    return { total: values.reduce((a, b) => a + b, 0), highest: Math.max(...values) };
  });
};

const getValuesInPeriod = (datasets: dataSetAble[], axis: string, period: number): number[] => {
  return datasets.filter((ds) => ds.yAxisID === axis).map((ds) => (ds.data && ds.data[period]) || 0) || [0];
};

export const buildTimeDataSets = (
  pData: PanelData,
  options: SimpleOptions,
  timeRange: TimeRange,
  interval: string
): ChartData => {
  const { series } = pData;

  const { groupLabels, dataSets, dataSetLabels, intervalTotals } = groupByValue(series, options);
  return {
    datasets: dataSets.map((ds, i) => {
      const label = dataSetLabels[i];
      const isline =
        (options.type === 'StackedBar' || options.type === 'GroupedBar') &&
        options.barTypes &&
        options.barTypes.includes(label);

      return {
        backgroundColor:
          options.colorMode === 'Group'
            ? ds.map((col, j) => getColor(j, options.colorPalette))
            : getColor(i, options.colorPalette),
        borderColor:
          options.colorMode === 'Group'
            ? ds.map((col, j) => getColor(j, options.colorPalette))
            : getColor(i, options.colorPalette),
        borderWidth: isline ? 2 : 0,
        data: ds.map((value, i) =>
          options.type === 'PercentStack' ? valueToPercent(value, intervalTotals[i]) : value
        ),
        rawData: options.type === 'PercentStack' ? ds : [],
        fill: false,
        label,
        pointRadius: 3,
        type: isline || options.type === 'Line' ? 'line' : 'bar',
        yAxisID: isline ? 'line-y-axis' : 'bar-y-axis',
        lineTension: parseFloat(options.lineTension),
        spanGaps: options.spanGaps,
      };
    }),
    labels:
      options.dateFormat === 'Range'
        ? rangeLabels(groupLabels, options.dateFormat, timeRange, interval)
        : groupLabels.map((item, intervalId) => formatDate(item, options.dateFormat, interval)),
  };
};

const valueToPercent = (value: number, total: number) => {
  return Math.floor((value / total) * 10000) / 100;
};

const rangeLabels = (
  groupLabels: string[],
  dateFormat: string,
  timeRange: TimeRange,
  interval: string
): Array<string | string[]> => {
  const labels: Array<string | string[]> = [];
  groupLabels.forEach((item, intervalId) => {
    const startDate: Date = intervalId === 0 ? timeRange.from.toDate() : new Date(item);
    const endDate: Date =
      intervalId === groupLabels.length - 1 ? timeRange.to.toDate() : subDays(new Date(groupLabels[intervalId + 1]), 1);
    if (interval === 'Hour') {
      labels.push(format(startDate, 'LLL do HH:mm'));
    } else if (isSameDay(startDate, endDate)) {
      labels.push(format(startDate, 'E do LLL yyyy'));
    } else if (interval === 'Month' && isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate)) {
      labels.push(format(startDate, 'LLL yyyy'));
    } else if (
      interval === 'Year' &&
      isSameDay(startOfYear(startDate), startDate) &&
      isSameDay(endOfYear(endDate), endDate)
    ) {
      labels.push(format(startDate, 'yyyy'));
    } else if (interval === 'Week' && isMonday(startDate) && isSunday(endDate)) {
      labels.push(`w/c ${format(startDate, 'E do LLL yyyy')}`);
    } else {
      labels.push([`${format(startDate, 'E do LLL')} to `, format(endDate, 'E do LLL yyyy')]);
    }
  });
  return labels;
};

const formatDate = (item: string, dateFormat: string, interval: string): string => {
  const date: Date = new Date(item);
  switch (dateFormat) {
    case 'Auto':
      switch (interval) {
        case 'Day':
        case '1d':
          return format(date, 'dd LLL yyyy');
        case 'Week':
        case '1w':
          return `From ${format(date, 'dd LLL yyyy')}`;
        case 'Month':
        case '1m':
          return format(date, 'LLL yyyy');
        case 'Quarter':
          return `Q${format(date, 'Q yyyy')}`;
        case 'Year':
        case '1y':
          return format(date, 'yyyy');
        default:
          return item;
      }
    // return ['1w', '1m', '1y', '7d', '14d', '30d', 'Week', 'Month', 'Year'].includes(interval)
    //   ? `From ${format(date, 'E do LLL yyyy')}`
    //   : interval === '1d' || interval === 'Day'
    //   ? format(date, 'E do LLL yyyy')
    //   : format(date, 'LLL do HH:mm');
    case 'Date':
      return format(date, 'dd LLL yyyy');
    case 'DateTime':
      return format(date, 'dd LLL yyyy HH:mm');
    case 'MonthYear':
      return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
    case 'Month':
      return monthNames[date.getMonth()];
    case 'Year':
      return date.getFullYear().toString();
    default:
      return item;
  }
};

const groupByValue = (series: DataFrame[], options: SimpleOptions) => {
  const groupLabels: string[] = extractLabelsFromValues(series);
  const dataSetLabels: string[] = extractLabelsFromFields(series, options.order);
  const { dataSets, intervalTotals } = buildDataSets(series, 'Value', dataSetLabels, groupLabels);
  return { groupLabels, dataSets, dataSetLabels, intervalTotals };
};

const extractLabelsFromValues = (series: DataFrame[]) => {
  const labelSet: Set<string> = new Set();
  series.map(
    (query) => query.fields.length > 0 && query.fields[timeIndex].values.toArray().map((value) => labelSet.add(value))
  );

  return [...labelSet].sort();
};

const getDataSetName = (field: Field<any, Vector<any>>) => {
  // return (field.labels && field.labels.type) || field.name || ''
  return (field.labels && field.labels[Object.keys(field.labels)[0]]) || field.name || '';
};

const extractLabelsFromFields = (series: DataFrame[], order: string) => {
  const labelArray: string[] = [];
  series.map((query) =>
    query.fields
      .filter((field) => field.type === 'number')
      .map((field) => {
        labelArray.push(getDataSetName(field));
      })
  );
  const orderLabels = order && order.length > 0 ? order.split(',').map((item) => item.trim()) : [];
  return [...new Set([...labelArray, ...orderLabels])].sort((a, b) => getSortOrder(order, a) - getSortOrder(order, b));
};

const getSortOrder = (order: string, key: string) => {
  return order ? (order.indexOf(String(key)) === -1 ? 99999 : order.indexOf(String(key))) : -1;
};

const buildDataSets = (series: DataFrame[], type: string, dataSetLabels: string[], groupLabels: string[]) => {
  const intervalTotals: number[] = Array(groupLabels.length).fill(0);
  const dataSets: number[][] = Array(dataSetLabels.length)
    .fill(0)
    .map(() => Array(groupLabels.length).fill(0));
  series.map((query) => {
    query.fields
      .filter((field) => field.type === 'number')
      .map((field) => {
        field.values.toArray().map((value, index) => {
          const dataSetName = getDataSetName(field);
          const groupName = query.fields[timeIndex].values.toArray()[index];
          dataSets[dataSetLabels.indexOf(dataSetName)][groupLabels.indexOf(groupName)] = value;
          intervalTotals[groupLabels.indexOf(groupName)] += value;
        });
      });
  });

  return { dataSets, intervalTotals };
};
