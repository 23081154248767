import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';

import '../styles/styles.scss';
import { buildTimeDataSets, findMaxes } from 'util/customData';

import { Bar, Line } from 'react-chartjs-2';
import Chart, { ChartOptions, ChartData } from 'chart.js';
import CustomVisualData from './CustomVisualData';

import {
  zeroWhy,
  uiLineOptions,
  uiBarGroupOptions,
  uiBarStackOptions,
  uiPercentStackOptions,
} from '../util/customOptions';

import { cloneDeep } from 'lodash';

interface Props extends PanelProps<SimpleOptions> {}

export const CustomTime: React.FC<Props> = ({ options, data, width, height, replaceVariables, timeRange }) => {
  if (data.state === 'Error') {
    return (
      <div>
        <h2>{data.state}</h2>
        <p>{data.error?.message}</p>
      </div>
    );
  }

  const interval = replaceVariables('$interval');
  const uiData = buildTimeDataSets(data, options, timeRange, interval);

  Chart.Tooltip.positioners.zeroWhy = zeroWhy;

  const renderChart = (uiData: {}) => {
    switch (options.type) {
      case 'Line':
        return <Line data={uiData} options={addAxes(uiData, uiLineOptions)} />;
      case 'GroupedBar':
        return <Bar data={uiData} options={addAxes(uiData, uiBarGroupOptions)} />;
      case 'StackedBar':
        return <Bar data={uiData} options={addAxes(uiData, uiBarStackOptions)} />;
      case 'PercentStack':
        return <Bar data={uiData} options={addAxes(uiData, uiPercentStackOptions)} />;
      default:
        return <CustomVisualData data={data} />;
    }
  };

  const addAxes = (uiData: ChartData, chartOptions: ChartOptions): ChartOptions => {
    const needAxes = hasLineSets(uiData);
    const barLabel = options.yAxesBar || '';
    const lineLabel = options.yAxesLine || '';

    const newChartOptions = { ...chartOptions };

    const maxYAxis = (options.matchAxes && findMaxes(uiData, options.type)) || 0;

    if (newChartOptions.scales && newChartOptions.scales.yAxes) {
      const yAxes = newChartOptions.scales.yAxes.find((item) => item.id === 'bar-y-axis');
      if (yAxes) {
        yAxes.scaleLabel = {
          display: barLabel.length > 0,
          labelString: barLabel,
        };
        if (yAxes.ticks) {
          if (options.matchAxes) {
            yAxes.ticks.max = maxYAxis;
          } else if (options.maxYValueLeft) {
            yAxes.ticks.max = parseInt(options.maxYValueLeft, 10);
          } else {
            yAxes.ticks.max = undefined;
          }
        }
        if (yAxes.ticks) {
          yAxes.ticks.callback = (value) =>
            options.yAxesLabelLeft ? options.yAxesLabelLeft.replace('v', value.toString()) : value;
        }
      }
    }

    if (newChartOptions.scales && newChartOptions.scales.yAxes) {
      const yAxes = newChartOptions.scales.yAxes.find((item) => item.id === 'line-y-axis');
      if (yAxes) {
        yAxes.display = needAxes;
        yAxes.scaleLabel = {
          display: lineLabel.length > 0,
          labelString: lineLabel,
        };
        if (yAxes.ticks) {
          if (options.matchAxes) {
            yAxes.ticks.max = maxYAxis;
          } else if (options.maxYValueRight) {
            yAxes.ticks.max = parseInt(options.maxYValueRight, 10);
          } else {
            yAxes.ticks.max = undefined;
          }
        }
        if (yAxes.ticks) {
          yAxes.ticks.callback = (value) =>
            options.yAxesLabelRight ? options.yAxesLabelRight.replace('v', value.toString()) : value;
        }
      }
    }

    return cloneDeep(newChartOptions);
  };

  const hasLineSets = (uiData: ChartData) => {
    const lines = (uiData.datasets && uiData.datasets.filter((item) => item.type === 'line').length) || 0;
    return lines > 0;
  };
  return (
    <div className="rradar-time">
      <div className="chart" style={{ width, height }}>
        {renderChart(uiData)}
      </div>
    </div>
  );
};
