import React from 'react';
import { ColorPicker, Icon } from '@grafana/ui';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

interface CustomColorPaletteProps {
  palette: string[];
  onChange: any;
}
interface CustomColorPaletteState {
  palette: string[];
}

const DragHandle = SortableHandle(() => (
  <span className="palette__drag-handle">
    <Icon name="arrows-h" size="sm" />
  </span>
));

const RemoveColor = ({ colorId, removeColor }: { colorId: number; removeColor: any }) => (
  <span onClick={() => removeColor(colorId)} className="palette__remove-color">
    <Icon name="trash-alt" size="sm" />
  </span>
);

const SortableColors = SortableContainer(
  ({
    items,
    updateColor,
    removeColor,
  }: {
    items: string[];
    updateColor: (index: number, color: string) => void;
    removeColor: (index: number) => void;
  }) => {
    return (
      <div className="palette__container">
        {items.map((value, index) => (
          <ColorItem
            key={`item-${index}`}
            index={index}
            color={value}
            updateColor={updateColor}
            removeColor={removeColor}
            colorId={index}
          />
        ))}
      </div>
    );
  }
);

const ColorItem = SortableElement(
  ({
    color,
    updateColor,
    colorId,
    removeColor,
  }: {
    color: string;
    updateColor: (colorId: number, color: string) => void;
    colorId: number;
    removeColor: any;
  }) => {
    return (
      <div className="palette__item">
        <ColorPicker color={color} onChange={(value) => updateColor(colorId, value)}>
          {({ ref, showColorPicker, hideColorPicker }) => {
            return (
              <div
                ref={ref}
                onClick={showColorPicker}
                onMouseLeave={hideColorPicker}
                className="palette__swatch"
                style={{ backgroundColor: `${color}` }}
              />
            );
          }}
        </ColorPicker>
        <div className="palette__color-band">{color}</div>
        <DragHandle />
        <RemoveColor colorId={colorId} removeColor={removeColor} />
      </div>
    );
  }
);

export class CustomColorPalette extends React.Component<CustomColorPaletteProps, CustomColorPaletteState> {
  constructor(props: CustomColorPaletteProps) {
    super(props);
    this.state = {
      palette: this.props.palette,
    };
  }

  render() {
    return (
      <div>
        <div>
          <SortableColors
            items={this.state.palette}
            onSortEnd={this.onSortEnd}
            updateColor={this.updateColor}
            removeColor={this.removeColor}
            useDragHandle={true}
            axis="xy"
          />
        </div>
        <div>
          <button className="palette__add-color" onClick={this.addColor}>
            Add Color
          </button>
        </div>
      </div>
    );
  }

  private addColor = () => {
    this.updateState([...this.state.palette, '#CCCCCC']);
  };

  private updateColor = (colorId: number, color: string) => {
    const colors = [...this.state.palette];
    colors[colorId] = color;
    this.updateState(colors);
  };

  private removeColor = (colorId: number) => {
    this.updateState(this.state.palette.filter((item, id) => id !== colorId));
  };

  private onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.updateState(this.arrayMove(this.state.palette, oldIndex, newIndex));
  };

  private arrayMove(x: string[], from: number, to: number) {
    x = x.slice();
    x.splice(to < 0 ? x.length + to : to, 0, x.splice(from, 1)[0]);
    return x;
  }

  private updateState = (colors: string[]) => {
    this.setState(
      {
        palette: colors,
      },
      () => this.props.onChange(this.state.palette)
    );
  };
}
